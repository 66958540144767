import React from 'react';

import Layout from '../components/Layout/layout';
import SEO from '../components/SEO/seo';
import ContactUs from '../components/contact-us';

const ContactUsPage = () => (
    <Layout>
        <SEO title="Contact" />
        <ContactUs />
    </Layout>
);

export default ContactUsPage;
